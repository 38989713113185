<template>
  <div
    v-if="ad.status === 'published' || (ad.status === 'closed' && expiryDateValid)"
    class="sale-ad-single-sidebar-block"
  >
    <h4>This is your advert!</h4>
    <p v-if="ad.status === 'published'" class="mb-0">
      Other users will be able to contact via this page. You can use the share buttons for extra exposure.
    </p>
    <p v-else class="mb-0">
      This ad has been closed, but since it hasn't expired, you can still reopen it.
    </p>
    <hr class="my-3">
    <h4>Manage ad</h4>
    <div
      v-if="ad.status === 'published'"
      class="d-flex flex-wrap pt-1"
    >
      <a
        :href="`/my-ads/vets/${ad.id}/edit`"
        class="btn btn-warning flex-grow-1 me-2"
      >
        Edit Ad <i class="fad fa-edit" />
      </a>
      <btn
        type="button"
        class="btn btn-danger flex-grow-1 ms-2"
        @click="confirmClosing(ad)"
      >
        Unlist Ad <i class="fad fa-eye-slash" />
      </btn>
    </div>
    <div v-else class="d-flex">
      <btn
        type="button"
        class="btn btn-success me-2"
        @click="confirmReopening(ad)"
      >
        Reopen Ad
      </btn>
    </div>
    <hr class="my-3">
    <p class="mb-0">
      <strong>Note:</strong> only you see this notice as you are logged in.
    </p>
  </div>
</template>

<script>
import BaseValidationMixin from '@js/v-mixins/BaseValidationMixin';

export default {
  mixins: [BaseValidationMixin],

  props: {
    ad: {
      type: Object,
      required: true,
    },
  },

  computed: {
    expiryDateValid () {
      const expiryDate = this.ad.expiry_date;

      if (!expiryDate) {
        return true;
      }

      return new Date(expiryDate) > new Date();
    },
  },

  methods: {
    confirmClosing (ad) {
      const confirm = window.confirm(`Are you sure you want to close this ad? \n– ${ad.title}`);
      if (confirm) this.closeAd(ad);
    },

    confirmReopening (ad) {
      const confirm = window.confirm(`Are you sure you want to reopen this ad? \n– ${ad.title}`);
      if (confirm) this.reopenAd(ad);
    },

    closeAd (ad) {
      this.$alerts.hideAll();

      axios.post(`/api/advertiser/my-ads/vets/${ad.id}/closings`)
        .then(({ data }) => {
          if (data.error) return;

          this.$sessionAlerts.show('success', data.message);
          this.$router.redirectTo('/my-ads/vets/');
        }).catch((e) => this.displayValidationMessages(e));
    },

    reopenAd (ad) {
      this.$alerts.hideAll();

      axios.delete(`/api/advertiser/my-ads/vets/${ad.id}/closings/1`)
        .then(({ data }) => {
          if (data.error) return;

          this.$sessionAlerts.show('success', data.message);
          this.$router.redirectTo('/my-ads/vets/');
        }).catch((e) => this.displayValidationMessages(e));
    },
  },
};
</script>
